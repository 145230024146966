<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :showLogout="true" :profile="profile"/>
        <div class="container">
            <div class="row">
                <div class="col-3">
                    <div class="list-group">
                        <router-link to="/admin" class="list-group-item"><i class="fa fa-home"></i> <span>Inicio</span></router-link>

                        <router-link to="/admin/users" class="list-group-item"><i class="fa fa-book"></i> <span>Usuarios</span></router-link>
                        <router-link to="/admin/coupons" class="list-group-item"><i class="fa fa-credit-card"></i> <span>Cupones</span></router-link>
                        <router-link to="/admin/blog" class="list-group-item active"><i class="fa fa-edit"></i> <span>blog</span></router-link>
                        <router-link to="/admin/me" class="list-group-item"><i class="fa fa-key"></i> <span>Mis datos</span></router-link>

                    </div>
                </div>
                <div class="col-9">
                    <h2>Blog</h2>
                    <div class="">
                        últimas entradas de blog
                        <a  class="btn btn-info btn-sm" :href="`/admin/blog/add`">Nuevo</a>
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>Publicado el </th>
                                    <th>titulo</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="post in posts" :key="post.id">
                                    <td>{{post.post_date}}</td>
                                    <td>{{post.title}}</td>
                                    <td><a :href="`/admin/blog/edit/${post.id}`">editar</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <template>
                        <editor-content :editor="editor" />
                        </template>

                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<style scoped>
 .list-group {

     margin:auto;
     float:left;
     padding-top:20px;
    }
    .card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
</style>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from 'tiptap';

export default {
  components:{
      Header,
      Footer,
      EditorContent,
  },
  name: 'Profile',
  data() {
      return {
          editor: null,
          courses: this.$store.state.userData.courses,
          profile: this.$store.getters.getUser,
          posts: []
      }
  },
  mounted() {
    this.editor = new Editor({
      content: '<p>Default Content Here</p>',
              extensions: StarterKit,

    })
  },
  methods: {
      save() {
          axios.post()
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}
.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px}
  .course:hover{
      opacity:.8
  }
</style>
